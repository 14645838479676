<div mat-dialog-title class="dialog-head">
    <div class="dialog-title">
        <h3>{{ 'openSourceLicenses.headLine' | translate }}</h3>
    </div>
    <div class="dialog-close">
        <button mat-icon-button mat-dialog-close>
            <img src="../../../assets/img/close-icon.svg" />
        </button>
    </div>
</div>
<div mat-dialog-content>
    <table class="main-table" mat-table *ngIf="licenses | async as data; else indicator" [dataSource]="data">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>{{ 'openSourceLicenses.table.name' | translate }}</th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="licenses">
            <th mat-header-cell *matHeaderCellDef>{{ 'openSourceLicenses.table.license' | translate }}</th>
            <td mat-cell *matCellDef="let element">{{ element.licenses }}</td>
        </ng-container>

        <ng-container matColumnDef="url">
            <th mat-header-cell *matHeaderCellDef>{{ 'openSourceLicenses.table.url' | translate }}</th>
            <td mat-cell *matCellDef="let element">
                <a target="_blank" [href]="element.url">{{ element.url }}</a>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>

<ng-template #indicator>
    <div class="loading-placeholder">
        <ngx-skeleton-loader
            count="30"
            appearance="circle"
            [theme]="{
                width: '100%',
                height: '49px',
                'border-radius': '10px'
            }"
        >
        </ngx-skeleton-loader>
    </div>
</ng-template>
