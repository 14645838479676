import { createAction, props } from '@ngrx/store';
import { BoilerVariant } from '../types/Boiler';
import { Loop, LoopNumber } from '../types/Loop';
import { ProductVariants } from '../types/ProductVariants';
import { Project } from '../types/Project';
import {
    DHW,
    HeatingRegulationModes,
    HeatingSeparatorType,
    PumpingMethods,
    State,
    ZoneControlMethods,
} from '../types/State';

export const setProject = createAction('[Project] Set Project', props<{ project: Project }>());
export const removeProject = createAction('[Project] Remove Project');
export const restoreState = createAction('[Project] Restore State', props<{ state: State }>());
export const setBoiler = createAction('[Diagram] Set Boiler', props<{ boiler: BoilerVariant }>());
export const removeBoiler = createAction('[Diagram] Remove Boiler');

export const setProductVariants = createAction(
    '[Diagram] Set Product Variants',
    props<{ productVariants: ProductVariants }>()
);

export const setHatingRegulationMode = createAction(
    '[Diagram] Set Heating Regulation Mode',
    props<{ value: HeatingRegulationModes }>()
);
export const setPumpingMethod = createAction('[Diagram] Set Pumping Method', props<{ value: PumpingMethods }>());

export const setZoneControlMethod = createAction(
    '[Diagram] Set Zone Control method',
    props<{ value: ZoneControlMethods }>()
);

export const updateLoop = createAction('[Diagram] Update Loop', props<{ loop: Loop }>());
export const addLoop = createAction('[Diagram] Add Loop', props<{ loop: Loop }>());
export const removeAllLoops = createAction('[Diagram] Remove All Loops');
export const removeLoop = createAction('[Diagram] Remove Loop', props<{ loopNumber: LoopNumber }>());
export const setRightPanelVariable = createAction(
    '[Diagram] Set Right Panel Variable',
    props<{ path: keyof State | `otherValues.${string}`; value: string | number | undefined }>()
);

export const updateHS = createAction(
    '[Diagram] Update HS',
    props<{ hsType: HeatingSeparatorType; reuseFromExistingSystem: boolean }>()
);
export const setDHW = createAction('[Diagram] Set DHW', props<{ dhw: DHW }>());
export const removeDHW = createAction('[Diagram] Remove DHW');
