import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';

@Component({
    selector: 'software-licenses',
    templateUrl: './software-licenses.component.html',
    styleUrls: ['./software-licenses.component.scss'],
})
export class SoftwareLicensesComponent {
    constructor(private http: HttpClient) {}
    public displayedColumns = ['name', 'licenses', 'url'];
    public licenses = this.http.get<OpenSourceLicense[]>('/assets/licenses.json');
}

interface OpenSourceLicense {
    name: string;
    licenses: string;
    url: string;
}
