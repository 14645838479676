import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForbiddenAuthGuardService } from './core/forbidden/forbidden.auth.guard.service';
import { ForbiddenComponent } from './core/forbidden/forbidden.component';
import { RedirectComponent } from './core/redirect/redirect.component';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
    /**
     * In future main entrypoint will be project management application, so user will always enter with some project id.
     */
    {
        path: '',
        pathMatch: 'full',
        canActivate: [AuthGuardService],
        component: RedirectComponent,
    },
    {
        path: 'forbidden',
        component: ForbiddenComponent,
        canActivate: [ForbiddenAuthGuardService],
        pathMatch: 'full',
    },
    {
        path: 'project',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./designer/designer.module').then((m) => m.DesignerModule),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
