import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InMemoryCache } from '@apollo/client/core';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { HamburgerContentComponent } from './core/hamburger-content/hamburger-content.component';
import { SoftwareLicensesComponent } from './core/software-licenses/software-licenses.component';
import { MessagesGraphqlModule } from './messages-tab/graphql/messages-graphql.module';
import { HttpInterceptorService } from './services/http-interceptor.service';
import { projectReducer, stateReducer } from './store/reducers';
import { RootState } from './store/rootState';
export function createTranslateLoader(http: HttpClient) {
    return new MultiTranslateHttpLoader(
        http,
        environment.translationsUrls.map((url) => ({ prefix: url, suffix: '/download?file_format=i18next' }))
    );
}

@NgModule({
    declarations: [AppComponent, HamburgerContentComponent, SoftwareLicensesComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        CoreModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatButtonModule,
        MatIconModule,
        MatToolbarModule,
        MessagesGraphqlModule,
        MatTableModule,
        MatDialogModule,
        MatSidenavModule,
        NgxSkeletonLoaderModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en-US',
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        StoreModule.forRoot<RootState>({
            project: projectReducer,
            state: stateReducer,
        }),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true,
        },
        {
            provide: APOLLO_OPTIONS,
            useFactory: (httpLink: HttpLink) => {
                return {
                    cache: new InMemoryCache(),
                    link: httpLink.create({
                        uri: environment.graphqlUri,
                    }),
                };
            },
            deps: [HttpLink],
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
