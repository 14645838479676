import { BoilerVariant } from './Boiler';
import { Loop } from './Loop';

export interface State {
    boiler?: BoilerVariant;
    connectionData?: {
        logicalLink: string;
        variantName: string;
    };
    dhw?: DHW;

    heatingSeparator?: HeatingSeparator;
    loops: Loop[];

    centralHeatingRegulationMode?: HeatingRegulationModes;
    pumpingMethod?: PumpingMethods;
    zoneControlMethod?: ZoneControlMethods;
    gasType?: GasType;
    altitude?: string | number;
    otherValues?: {
        MixerOneCircuitSummerSavingTemperatureThresholdEnabled?: string;
        BoilerPumpModulation?: string;
        [key: string]: string | number | undefined;
    };

    rightPanelVariablesOptions?: RightPanelVariableOptions;
}
export interface RightPanelVariableOptions {
    pumpingMethod?: RightPanelVariableOption;
    zoneControlMethod?: RightPanelVariableOption;
}

export interface RightPanelVariableOption {
    editable?: boolean;
    limitOptionsTo?: string[];
}
export interface HeatingSeparator {
    reuseFromExistingSystem: boolean;
    type: HeatingSeparatorType;
}

export interface DHW {
    reuseFromExistingSystem?: boolean;
    temperature: string;
    tank?: {
        dhwCapacity: number;
        storageTankCapacity: number;
        temperatureControl: TempControl;
        pipingType: PipingType;
        recirculation: boolean;
    };
}

export enum TempControl {
    SENSOR = 'SENSOR',
    AQUASTAT = 'AQUASTAT',
}
export enum PipingType {
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY',
}
export enum HeatingSeparatorType {
    'NONE' = 'none',
    'CLOSELY_SPACED_TEE' = 'CloselySpacedTee',
    'LOW_LOSS_HEADER' = 'LowLossHeader',
    'BUFFER_TANK' = 'BufferTank',
}

export enum GasType {
    'NATURAL_GAS' = 'EGas',
    'LPG_GAS' = 'LiquidGas',
}
export enum HeatingRegulationModes {
    CONSTANT_CONTROLLED = 'ConstantControlled',
    WEATHER_BY_OUTSIDE_SENSOR_CONTROLLED = 'WeatherByOutsideSensorControlled',
}

export enum PumpingMethods {
    BOILER_PUMP = 'TI_BoilerPump',
    DISTRIBUTION_PUMP = 'TI_DistributionPump',
    LOOP_VALUE = 'TI_LoopValve',
    LOOP_PUMP = 'TI_LoopPump',
}

export enum ZoneControlMethods {
    NONE = 'TI_None',
    MZC = 'TI_ExternalMZC',
    END_SWITCHES = 'TI_EndSwitches',
    BOILER = 'TI_Boiler',
}
