/* eslint-disable sonarjs/no-duplicate-string */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../types/Users';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    requiredRoles = ['CUAR_Limas-ViPlanDesigner.B2B_REMOTE_DIAG'];
    constructor(private http: HttpClient) {}

    public user$ = this.http
        .get<User>(`${environment.usersUrl}`, {
            params: {
                sections: ['identity', 'roles'],
            },
        })
        .pipe(shareReplay());

    public userName$ = this.user$.pipe(map((user) => `${user.name.firstName} ${user.name.familyName}`));

    public hasViplanAccess$ = this.user$.pipe(
        map((user) => {
            const identityType = user.properties.find((item) => item.name === 'IdentityType')?.value.toLowerCase();

            return (
                identityType?.startsWith('vi') ||
                this.requiredRoles.every((role) => user.applicationRoles.includes(role))
            );
        })
    );
}
