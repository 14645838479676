import { createReducer, on } from '@ngrx/store';
import { AhuAndAirFlowPlusService } from '../services/ahu-air-flow-plus.service';
import { Emitters } from '../services/emitters.service';
import { Loop, LoopNumber } from '../types/Loop';
import { Project } from '../types/Project';
import {
    GasType,
    HeatingRegulationModes,
    HeatingSeparatorType,
    PipingType,
    PumpingMethods,
    State,
    ZoneControlMethods,
} from '../types/State';
import * as Actions from './actions';

//c8 ignore next
export const projectReducer = createReducer<Project | undefined>(
    undefined,
    on(Actions.setProject, (_state, { project }) => project),
    // eslint-disable-next-line @everest/no-useless-undefined
    on(Actions.removeProject, () => undefined)
);

export const stateReducer = createReducer<State>(
    { loops: [] },
    on(Actions.removeProject, () => ({ loops: [] })),
    on(Actions.removeBoiler, () => ({ loops: [] })),
    on(Actions.restoreState, (_, { state }) => state),
    on(Actions.setBoiler, (state, { boiler }) => ({
        ...state,
        boiler,
        connectionData:
            boiler.logicalLink && boiler.variant
                ? {
                      logicalLink: boiler.logicalLink,
                      variantName: boiler.variant,
                  }
                : undefined,
        heatingSeparator: {
            type:
                state.boiler?.family === boiler?.family && state.heatingSeparator?.type
                    ? state.heatingSeparator.type
                    : HeatingSeparatorType.NONE,
            reuseFromExistingSystem: false,
        },
        dhw: state.boiler?.family !== boiler?.family ? undefined : state.dhw,
        altitude: undefined,
        gasType: GasType.NATURAL_GAS,
        centralHeatingRegulationMode: HeatingRegulationModes.WEATHER_BY_OUTSIDE_SENSOR_CONTROLLED,
        pumpingMethod: PumpingMethods.BOILER_PUMP,
        zoneControlMethod: ZoneControlMethods.BOILER,
        otherValues: {
            MixerOneCircuitSummerSavingTemperatureThresholdEnabled: 'On',
            BoilerPumpModulation: 'ConstantSpeedBoilerPump',
        },
        rightPanelVariablesOptions: {
            pumpingMethod: {
                editable: false,
            },
            zoneControlMethod: {
                editable: false,
            },
        },
    })),
    on(Actions.setProductVariants, (state, { productVariants }) => ({
        ...state,
        connectionData: {
            logicalLink: productVariants.logicalLink,
            variantName: productVariants.variants[0],
        },
    })),
    on(Actions.addLoop, (state, { loop: newLoop }) =>
        updateLoops(state, [
            ...state.loops.map((loop) => {
                if (newLoop.isMain) {
                    return { ...loop, isMain: false };
                }
                return loop;
            }),
            newLoop,
        ])
    ),
    on(Actions.updateLoop, (state, { loop: updatedLoop }) =>
        updateLoops(
            state,
            state.loops.map((loop) => {
                if (loop.number === updatedLoop.number) {
                    return {
                        ...updatedLoop,
                        thermostaticMixingValveTemperature: updatedLoop.hasThermostaticMixingValve
                            ? updatedLoop.thermostaticMixingValveTemperature
                            : undefined,
                        fixedTemperature:
                            state.centralHeatingRegulationMode === HeatingRegulationModes.CONSTANT_CONTROLLED
                                ? updatedLoop.fixedTemperature
                                : undefined,
                    };
                }
                if (updatedLoop.isMain) {
                    return { ...loop, isMain: false };
                }
                return loop;
            })
        )
    ),
    on(Actions.removeAllLoops, (state) => ({
        ...state,
        loops: [],
        heatingSeparator: {
            type: HeatingSeparatorType.NONE,
            reuseFromExistingSystem: false,
        },
        zoneControlMethod: ZoneControlMethods.NONE,
    })),
    on(Actions.removeLoop, (state, { loopNumber }) => {
        const newState = updateLoops(
            state,
            state.loops.filter((loop) => loop.number !== loopNumber)
        );
        return {
            ...newState,
            zoneControlMethod:
                state.loops.length === 2 || !state?.zoneControlMethod
                    ? ZoneControlMethods.BOILER
                    : state?.zoneControlMethod,
        };
    }),
    on(Actions.updateHS, (state, { hsType, reuseFromExistingSystem }) => ({
        ...state,
        heatingSeparator: { type: hsType, reuseFromExistingSystem },
    })),
    on(Actions.setDHW, (state, { dhw }) => ({
        ...state,
        dhw,
        heatingSeparator:
            state.loops.length === 0 && dhw.tank?.pipingType === PipingType.PRIMARY
                ? { type: HeatingSeparatorType.NONE, reuseFromExistingSystem: false }
                : state.heatingSeparator,
    })),
    on(Actions.removeDHW, (state) => ({
        ...state,
        dhw: undefined,
        heatingSeparator:
            state.loops.length === 0
                ? { type: HeatingSeparatorType.NONE, reuseFromExistingSystem: false }
                : state.heatingSeparator,
    })),
    on(Actions.setRightPanelVariable, (state, { path, value }) => {
        if (path.startsWith('otherValues')) {
            return {
                ...state,
                otherValues: {
                    ...state.otherValues,
                    [path.substr('otherValues.'.length)]: value,
                },
            };
        }

        return {
            ...state,
            [path]: value,
        };
    }),
    on(Actions.setHatingRegulationMode, (state, { value }) => {
        const loops =
            state.loops.length &&
            value === HeatingRegulationModes.WEATHER_BY_OUTSIDE_SENSOR_CONTROLLED &&
            state.centralHeatingRegulationMode !== HeatingRegulationModes.WEATHER_BY_OUTSIDE_SENSOR_CONTROLLED
                ? resetLoops(state.loops)
                : state.loops;
        return {
            ...state,
            loops,
            centralHeatingRegulationMode: value,
            otherValues: {
                ...state.otherValues,
                'MixerOneCircuitSummerSavingTemperatureThreshold.Enable':
                    value === HeatingRegulationModes.CONSTANT_CONTROLLED ? 'Off' : 'On',
            },
        };
    }),
    on(Actions.setPumpingMethod, (state, { value }) => {
        return {
            ...state,
            pumpingMethod: value,
        };
    }),
    on(Actions.setZoneControlMethod, (state, { value }) => {
        return {
            ...state,
            zoneControlMethod: value,
        };
    }),
    on(Actions.updateHS, Actions.setPumpingMethod, (state) => {
        return {
            ...state,
            pumpingMethod: calculateNewPumpingMethods(state, state.loops),
            zoneControlMethod: calculateNewZoneControlMethod(state, state.loops),
        };
    }),
    on(
        Actions.restoreState,
        Actions.updateLoop,
        Actions.removeLoop,
        Actions.addLoop,
        Actions.updateHS,
        Actions.setPumpingMethod,
        (state) => {
            const loopsCount = state.loops.length;
            const loopsWithThermostaticMixingValve =
                state.loops.filter((l) => l.hasThermostaticMixingValve).length || 0;
            const pumpingMethod = state.pumpingMethod;
            const hasAhuOrAirflowPlus = AhuAndAirFlowPlusService.hasAhuOrAirflowPlus(state.loops);

            return {
                ...state,
                rightPanelVariablesOptions: {
                    zoneControlMethod: {
                        limitOptionsTo:
                            loopsCount >= 2 ? [ZoneControlMethods.MZC, ZoneControlMethods.END_SWITCHES] : undefined,
                        editable: loopsCount >= 2 && pumpingMethod !== PumpingMethods.LOOP_PUMP && !hasAhuOrAirflowPlus,
                    },
                    pumpingMethod: {
                        limitOptionsTo:
                            loopsCount >= 2 ? [PumpingMethods.DISTRIBUTION_PUMP, PumpingMethods.LOOP_PUMP] : undefined,
                        editable: loopsCount > 1 && loopsWithThermostaticMixingValve === 0 && !hasAhuOrAirflowPlus,
                    },
                },
            };
        }
    )
);

function updateLoops(state: State, newLoops: Loop[]) {
    const loops =
        state.centralHeatingRegulationMode === HeatingRegulationModes.CONSTANT_CONTROLLED
            ? performFixedValveLogic(newLoops)
            : newLoops;

    return <State>{
        ...state,
        loops: sortLoops(loops),
        pumpingMethod: calculateNewPumpingMethods(state, loops),
        zoneControlMethod: calculateNewZoneControlMethod(state, loops),
    };
}

export function sortLoops(loops: Loop[]) {
    return loops
        .sort((a, b) => Number.parseInt(a.number) - Number.parseInt(b.number))
        .map((loop, index) => ({ ...loop, number: <LoopNumber>`${index + 1}` }));
}

function performFixedValveLogic(loops: Loop[]) {
    const maxLoop = loops
        .filter((l) => l.emitterMode !== Emitters.NONE)
        .map((v) => ({
            number: v.number || '-1',
            fixedTemperature: v?.fixedTemperature || -9999,
        }))
        .reduce((a, b) => (a.fixedTemperature > b.fixedTemperature ? a : b), {
            number: '-1',
            fixedTemperature: -999,
        });
    if (maxLoop.number !== '-1') {
        return loops.map((loop) => ({
            ...loop,
            isMain: loop.number === maxLoop.number,
            hasThermostaticMixingValve: (loop.fixedTemperature || 0) < maxLoop.fixedTemperature,
        }));
    } else {
        return resetLoops(loops);
    }
}

function calculateNewZoneControlMethod(state: State, newLoops: Loop[]) {
    const zoneControlMethod = state.zoneControlMethod || ZoneControlMethods.BOILER;

    if (newLoops.length <= 1) {
        return zoneControlMethod;
    }

    if (
        ![ZoneControlMethods.MZC, ZoneControlMethods.END_SWITCHES].includes(zoneControlMethod) ||
        AhuAndAirFlowPlusService.hasAhuOrAirflowPlus(newLoops) ||
        state.pumpingMethod === PumpingMethods.LOOP_PUMP
    ) {
        return ZoneControlMethods.MZC;
    }

    return zoneControlMethod;
}

function calculateNewPumpingMethods(state: State, newLoops: Loop[]): PumpingMethods {
    const oldLoops = state.loops;

    if (AhuAndAirFlowPlusService.hasAhuOrAirflowPlus(newLoops) || (loopsHaveTMV(newLoops) && newLoops.length > 1)) {
        return PumpingMethods.LOOP_PUMP;
    }

    if (added2ndLoop(oldLoops, newLoops)) {
        return PumpingMethods.DISTRIBUTION_PUMP;
    }

    if (newLoops.length === 1) {
        return state.heatingSeparator?.type === HeatingSeparatorType.NONE
            ? PumpingMethods.BOILER_PUMP
            : PumpingMethods.DISTRIBUTION_PUMP;
    }
    return state.pumpingMethod || PumpingMethods.BOILER_PUMP;
}

function loopsHaveTMV(newLoops: Loop[]) {
    return newLoops.find((loop) => loop.hasThermostaticMixingValve);
}

function added2ndLoop(oldLoops: Loop[], newLoops: Loop[]) {
    return oldLoops.length === 1 && newLoops.length === 2;
}

function resetLoops(loops: Loop[]) {
    return loops.map((loop) => ({
        ...loop,
        hasThermostaticMixingValve: false,
        isMain: false,
    }));
}
