import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { SoftwareLicensesComponent } from '../software-licenses/software-licenses.component';

@Component({
    selector: 'hamburger-content',
    templateUrl: './hamburger-content.component.html',
    styleUrls: ['./hamburger-content.component.scss'],
})
export class HamburgerContentComponent {
    readonly currentYear = new Date().getFullYear();
    @Output() toggleHamburger = new EventEmitter();
    public versionParam = { version: environment.version };

    constructor(public dialog: MatDialog) {}

    openViplanLicences() {
        this.toggleHamburger.next();
        this.dialog.open(SoftwareLicensesComponent);
    }
}
