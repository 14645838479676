import { Component } from '@angular/core';

@Component({
    selector: 'forbidden-component',
    template: `
        <div class="icon-container"><mat-icon class="icon">warning</mat-icon></div>
        <h1>{{ 'forbiddenPage.headline' | translate }}</h1>
        <span>{{ 'forbiddenPage.content' | translate }}</span>

        <div class="actions d-flex align-items-center justify-content-center">
            <button mat-stroked-button color="accent" (click)="viLogin.logout()">
                {{ 'forbiddenPage.actions.logout' | translate }}
            </button>
        </div>
        <vih-login #viLogin style="display: none;" [appManagesAuthentication]="true"></vih-login>
    `,
    styleUrls: ['./forbidden.component.scss'],
})
export class ForbiddenComponent {}
