import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalHeaderModule } from '@vi/global-header';
import { environment } from 'src/environments/environment';
import { FooterComponent } from './footer/footer.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { HeaderComponent } from './header/header.component';
import { RedirectComponent } from './redirect/redirect.component';

@NgModule({
    declarations: [HeaderComponent, FooterComponent, ForbiddenComponent, RedirectComponent],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        GlobalHeaderModule.forRoot({
            appId: environment.auth.appId,
            baseUrl: environment.auth.baseUrl,
            accountAppUrl: environment.auth.registerBaseUrl,
        }),
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        FlexModule,
    ],
    exports: [FooterComponent, HeaderComponent],
})
export class CoreModule {}
