import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InMemoryCache } from '@apollo/client/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { environment } from '../../../environments/environment';
import { MessagesService } from './messages.service';

@NgModule({
    declarations: [],
    imports: [CommonModule],
    providers: [
        {
            provide: APOLLO_OPTIONS,
            useFactory: (httpLink: HttpLink) => {
                return {
                    cache: new InMemoryCache(),
                    link: httpLink.create({
                        uri: environment.graphqlUri,
                    }),
                };
            },
            deps: [HttpLink],
        },
        MessagesService,
    ],
})
export class MessagesGraphqlModule {}
