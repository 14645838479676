<div class="root h-100 d-flex flex-column">
    <div class="navbar flex-grow-0">
        <app-header (menuButtonClick)="toggleHamburger()"></app-header>
    </div>
    <div class="content flex-grow-1">
        <mat-sidenav-container class="h-100">
            <mat-sidenav mode="push" [opened]="hamburgerMenuOpened">
                <hamburger-content (toggleHamburger)="toggleHamburger()"></hamburger-content>
            </mat-sidenav>
            <mat-sidenav-content>
                <router-outlet></router-outlet>
            </mat-sidenav-content>
        </mat-sidenav-container>
    </div>
</div>
