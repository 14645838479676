import { Component, EventEmitter, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    @Output() public menuButtonClick = new EventEmitter();
    authenticated$: Observable<boolean>;
    constructor(public auth: AuthService) {
        this.authenticated$ = this.auth.csrfToken$.pipe(
            delay(10),
            map((token) => {
                return !!token;
            })
        );
    }
}
