import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
    constructor(private auth: AuthService, private userService: UserService, private router: Router) {}

    canActivate(): Observable<boolean> {
        return this.auth.getCsrfToken$().pipe(
            switchMap(() => this.userService.hasViplanAccess$),
            tap((hasAccessToViplan) => {
                if (hasAccessToViplan === false) {
                    this.router.navigate(['forbidden']);
                }
            })
        );
    }
}
