import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Injectable({
    providedIn: 'root',
})
export class ForbiddenAuthGuardService implements CanActivate {
    constructor(private auth: AuthService, private userService: UserService, private router: Router) {}

    canActivate(): Observable<boolean> {
        return this.auth.getCsrfToken$().pipe(
            switchMap(() => this.userService.hasViplanAccess$),
            map((hasViplanAccess) => !hasViplanAccess),
            tap((shouldDisplayForbiddenPage) => {
                if (shouldDisplayForbiddenPage === false) {
                    this.router.navigate(['']);
                }
            })
        );
    }
}
