import { Injectable } from '@angular/core';
import { Apollo, gql, QueryRef } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { MessageInput, Thread } from './graphql.models';

const threadFields = `
            id
            projectId
            lastMessageBy
            lastMessageDate
            threadType
            isArchived
            messages {
                author
                date
                text
                recipient
                recipientId
            }
`;

const GET_THREADS = gql`
    query threads ($id: ID!) {
        threads (id: $id) {
            ${threadFields}
        }
    }
`;

const ADD_THREAD = gql`
    mutation addThread ($projectId: ID!, $message: MessageInput) {
        addThread (projectId: $projectId, message: $message) {
            ${threadFields}
        }
    }
`;

const ADD_MESSAGE = gql`
    mutation addMessageToThread ($threadId: ID!, $message: MessageInput) {
        addMessageToThread (threadId: $threadId, message: $message) {
            ${threadFields}
        }
    }
`;

const ARCHIVE_THREAD = gql`
    mutation archiveThread($threadId: ID!) {
        archiveThread(threadId: $threadId) {
            id
            projectId
            lastMessageBy
            lastMessageDate
            threadType
            isArchived
            messages {
                author
                date
                text
                recipient
                recipientId
            }
        }
    }
`;

@Injectable()
export class MessagesService {
    messagesQuery?: QueryRef<{ threads: Thread[] }, { id: string }>;

    constructor(private apollo: Apollo) {}

    getThreads(projectId: string): Observable<Thread[]> {
        this.messagesQuery = this.apollo.watchQuery({
            query: GET_THREADS,
            fetchPolicy: 'no-cache',
            variables: {
                id: projectId,
            },
        });
        return this.messagesQuery.valueChanges.pipe(map((response) => response.data.threads));
    }

    addThread(projectId: string, message: MessageInput): Promise<unknown> {
        return this.apollo
            .mutate<{ addThread: unknown }>({
                mutation: ADD_THREAD,
                variables: {
                    projectId,
                    message,
                },
            })
            .pipe(
                tap(() => {
                    this.messagesQuery?.refetch();
                })
            )
            .pipe(map((response) => response.data?.addThread))
            .toPromise();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addMessage(threadId: string, message: MessageInput): Promise<any> {
        return this.apollo
            .mutate<{ addMessageToThread: unknown }>({
                mutation: ADD_MESSAGE,
                variables: {
                    threadId,
                    message,
                },
            })
            .pipe(
                tap(() => {
                    this.messagesQuery?.refetch();
                })
            )
            .pipe(map((response) => response.data?.addMessageToThread))
            .toPromise();
    }

    archiveThread(threadId: string): Promise<Thread | undefined> {
        return this.apollo
            .mutate<{ archiveThread: Thread }>({
                mutation: ARCHIVE_THREAD,
                variables: {
                    threadId,
                },
            })
            .pipe(
                tap(() => {
                    this.messagesQuery?.refetch();
                })
            )
            .pipe(map((response) => response.data?.archiveThread))
            .toPromise();
    }
}
