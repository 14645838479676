<div fxLayout="column">
    <div fxFlex="70px" fxLayout="row" fxLayoutGap="20px" fxLayoutGap.xs="10px" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutAlign="left center">
            <button mat-icon-button color="white" (click)="menuButtonClick.emit()">
                <mat-icon class="color-white">menu</mat-icon>
            </button>
            <a fxLayout="row" fxLayoutAlign="center center" routerLink="/" class="app-title">
                <img src="../../../../assets/img/viessmann-white.svg" class="logo-img" />
                <span class="title">{{ 'header.appTitle' | translate }}</span>
            </a>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center">
            <vih-app-switcher></vih-app-switcher>
            <vih-login [appManagesAuthentication]="true" [authenticated]="authenticated$"></vih-login>
        </div>
    </div>
</div>
