import { Injectable } from '@angular/core';
import { Loop } from '../types/Loop';
import { PumpingMethods, State, ZoneControlMethods } from '../types/State';
import { EmittersService } from './emitters.service';

@Injectable({
    providedIn: 'root',
})
export class AhuAndAirFlowPlusService {
    public static hasAhuOrAirflowPlus(loops?: Loop[]) {
        return (loops || []).some((loop: Loop) => EmittersService.isAhuOrAirflowPlus(loop.emitterMode));
    }

    static getRequiredAhuChanges(state: State, loopsAfterChanges: Loop[]) {
        if (!AhuAndAirFlowPlusService.hasAhuOrAirflowPlus(loopsAfterChanges)) {
            return { pumpMethodChangeRequired: false, zoneControlMethodChangeRequired: false };
        }

        const pumpMethodChangeRequired = state.pumpingMethod !== PumpingMethods.LOOP_PUMP;
        const zoneControlMethodChangeRequired =
            loopsAfterChanges.length > 1 && state.zoneControlMethod !== ZoneControlMethods.MZC;

        return { pumpMethodChangeRequired, zoneControlMethodChangeRequired };
    }

    static validateIfChangesAreRequired(currentState: State, loopsAfterChanges: Loop[]) {
        if (AhuAndAirFlowPlusService.hasAhuOrAirflowPlus(currentState?.loops)) {
            return false;
        }

        const { pumpMethodChangeRequired, zoneControlMethodChangeRequired } =
            AhuAndAirFlowPlusService.getRequiredAhuChanges(currentState, loopsAfterChanges);
        const mode = AhuAndAirFlowPlusService.hasAhu(loopsAfterChanges) ? 'ahu' : 'airflowPlus';

        if (pumpMethodChangeRequired || zoneControlMethodChangeRequired) {
            const options = [];
            if (pumpMethodChangeRequired) {
                options.push('loopAdd.confirmDialog.ahuOrAirflowPlus.message.loopPumpChanged');
            }
            if (zoneControlMethodChangeRequired) {
                options.push('loopAdd.confirmDialog.ahuOrAirflowPlus.message.zoneControlMethodChange');
            }
            return {
                bodyKey: `loopAdd.confirmDialog.ahuOrAirflowPlus.message.${mode}`,
                options,
            };
        }

        return false;
    }

    private static hasAhu(loops: Loop[]) {
        return loops.some((loop) => EmittersService.isAhu(loop.emitterMode));
    }
}
