import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-redirect',
    template: ``,
    styles: [],
})
export class RedirectComponent implements OnInit {
    constructor(private router: Router) {}

    ngOnInit(): void {
        if (environment.initRedirect) {
            this.router.navigate([environment.initRedirect]);
        } else {
            window.location.href = environment.pmToolUrl;
        }
    }
}
