/* eslint-disable sonarjs/no-duplicate-string */
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Emitter } from '../types/Emitter';

export enum Emitters {
    NONE = 'NONE',
    SNOWMELT = 'Snowmelt',
    RADIANT_UNDER_FLOOR_HEATING = 'RadiantUnderfloorHeating',
    CAST_IRON_RADIATOR = 'CAST_IRON_RADIATOR',
    PANEL_RADIATOR = 'Radiator',
    BASEBOARD = 'Baseboard',
    AHU = 'AirHandler',
    AIRFLOW_PLUS = 'AIRFLOW_PLUS',
    CUSTOM = 'custom',
}

@Injectable({
    providedIn: 'root',
})
export class EmittersService {
    static NONE_EMITTER = { name: 'NONE', icon: 'icon-none.svg', enabled: true };

    private _emitters: Emitter[] = [
        EmittersService.NONE_EMITTER,
        {
            name: Emitters.AHU,
            icon: 'icon-ahu.svg',
            enabled: true,
            isAhuOrAirflowPlus: true,
            values: {
                minTemperature: {
                    imperial: 150,
                    metric: 65,
                },
                maxTemperature: {
                    imperial: 180,
                    metric: 82,
                },
                defaultFixedTemperature: {
                    imperial: 165,
                    metric: 74,
                },
                slope: 1,
                shift: 0,
            },
        },
        {
            name: Emitters.AIRFLOW_PLUS,
            icon: 'icon-airflow-plus.svg',
            enabled: true,
            isAhuOrAirflowPlus: true,
            values: {
                minTemperature: {
                    imperial: 120,
                    metric: 50,
                },
                maxTemperature: {
                    imperial: 180,
                    metric: 82,
                },
                defaultFixedTemperature: {
                    imperial: 140,
                    metric: 60,
                },
                slope: 0.7,
                shift: 25,
            },
        },
        {
            name: Emitters.BASEBOARD,
            icon: 'icon-baseboard.svg',
            enabled: true,
            values: {
                minTemperature: {
                    imperial: 120,
                    metric: 50,
                },
                maxTemperature: {
                    imperial: 180,
                    metric: 82,
                },
                defaultFixedTemperature: {
                    imperial: 170,
                    metric: 77,
                },
                slope: 1.6,
                shift: 0,
            },
        },
        {
            name: Emitters.CAST_IRON_RADIATOR,
            icon: 'icon-cast-iron-radiator.svg',
            enabled: true,
            values: {
                minTemperature: {
                    imperial: 90,
                    metric: 32,
                },
                maxTemperature: {
                    imperial: 130,
                    metric: 54,
                },
                defaultFixedTemperature: {
                    imperial: 125,
                    metric: 52,
                },
                slope: 0.8,
                shift: 0,
            },
        },

        {
            name: Emitters.PANEL_RADIATOR,
            icon: 'icon-panel-radiator.svg',
            enabled: true,
            values: {
                minTemperature: {
                    imperial: 90,
                    metric: 32,
                },
                maxTemperature: {
                    imperial: 160,
                    metric: 70,
                },
                defaultFixedTemperature: {
                    imperial: 150,
                    metric: 66,
                },
                slope: 1.4,
                shift: 0,
            },
        },
        {
            name: Emitters.RADIANT_UNDER_FLOOR_HEATING,
            icon: 'icon-radiant-underfloor.svg',
            enabled: true,
            values: {
                minTemperature: {
                    imperial: 80,
                    metric: 28,
                },
                maxTemperature: {
                    imperial: 140,
                    metric: 60,
                },
                defaultFixedTemperature: {
                    imperial: 151250,
                    metric: 52,
                },
                slope: 0.6,
                shift: 0,
            },
        },
        {
            name: Emitters.SNOWMELT,
            icon: 'icon-snowmelt.svg',
            enabled: true,
            values: {
                minTemperature: {
                    imperial: 80,
                    metric: 28,
                },
                maxTemperature: {
                    imperial: 100,
                    metric: 38,
                },
                defaultFixedTemperature: {
                    imperial: 90,
                    metric: 32,
                },
                slope: 0.3,
                shift: 0,
            },
        },
        {
            name: Emitters.CUSTOM,
            icon: 'icon-custom.svg',
            enabled: true,
            values: {
                minTemperature: {
                    imperial: 80,
                    metric: 28,
                },
                maxTemperature: {
                    imperial: 180,
                    metric: 82,
                },
                defaultFixedTemperature: {
                    imperial: 130,
                    metric: 54,
                },
                slope: 0,
                shift: 0,
            },
        },
    ];
    public static isAhuOrAirflowPlus(emitterType: string) {
        return emitterType === Emitters.AHU || emitterType === Emitters.AIRFLOW_PLUS;
    }

    public static isAirflowPlus(emitterType: string) {
        return emitterType === Emitters.AIRFLOW_PLUS;
    }

    public static isAhu(emitterType: string) {
        return emitterType === Emitters.AHU;
    }

    public get emittersForList$() {
        // this might be fetched from backend in future so we will keep it as observable
        return of(this._emitters);
    }

    public getEmitter(name: string): Observable<Emitter> {
        return this.emittersForList$.pipe(
            take(1),
            map((emitters) => <Emitter>emitters.find((emitter) => emitter.name === name))
        );
    }
}
